<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading || paymentMethod == null">
      <span class="loader"></span>
    </span>
    <div
      v-if="!loading && paymentMethod != null"
      class="elite-tabs-wrapper mb-5"
    >
      <div class="paymentMethod">
        <div class="row">
          <div class="col-12">

            <div class="row mt-2">
                <h5 class="col-2">{{ $t("WALLET.TYPE") }}</h5>
                <h5 class="col-10">
                    {{ $t(`WALLET.${paymentMethod.type}`) }}
                </h5>
            </div>

            <div class="payment-method-details mt-3">
                <h4><span>{{ $t("WALLET.DETAILS")}}</span></h4>
                <object-details :data="getDetails(paymentMethod.details)" />
            </div>
            <div class="payment-method-holder mt-3">
                <h4><span>{{ $t("WALLET.PAYMENT_METHOD_HOLDER")}}</span></h4>
                <object-details :data="paymentMethod.details.holder" />
            </div>
            <div v-if="paymentMethod.signers" class="payment-method-signers mt-3">
                <h4><span>{{ $t("WALLET.SIGNERS")}}</span></h4>
                <object-details
                  :data="getSigners(paymentMethod.signers)"
                  :with-trad="false"
                />
            </div>

            <div class="row mt-3">
                <h5 class="col-2">{{ $t("COMMON.CREATED_AT") }}</h5>
                <h5 class="col-10">
                    {{ $formatDate(paymentMethod.created_at) }}
                </h5>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import requestErrorMixin from "@/mixins/request-error-mixin";
import ObjectDetails from "./atoms/ObjectDetails.vue";

export default {
  name: "ViewPaymentMethodComponent",
  components: {
    ObjectDetails,
  },
  layout: "DashboardLayout",
  mixins: [requestErrorMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          this.get();
        }
      },
      deep: true,
    },
  },

  data() {
    return {
        paymentMethod:null,
        loading:false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        this.loading = true;
        const response = await this.$axios.get(
          `/elixio-wallet/payment-methods/${this.id}`
        );
        if (response.data.success) {
          this.paymentMethod = response.data.data;
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getDetails(details) {
      return {
        bank_name: details.bank_name,
        account_number: details.account_number,
        branch_code: details.branch_code,
        transit_number: details.transit_number,
        bank_acronym: details.bank_acronym,
      };
    },

    getSigners(signers) {
      return signers.reduce((acc, signer) => {
        const fullName = `${signer.firstname} ${signer.lastname}`;
        acc[fullName] = signer.email;
        return acc;
      }, {});
    },
  },
};
</script>
