<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ organization.name }} - {{ $t("ORGANIZATIONS.ACCOUNTS") }}
    </h3>
    <div class="col-12">

      <el-collapse accordion>

        <el-collapse-item v-if="organization.onespan_api_key" name="oneSpan" class="list-group">
          <template slot="title">
            <span class="m-2 organization-account-icon">
              <esign-icon width="20px" class="custom-svg-icon" />
            </span>
            {{ $t("ORGANIZATIONS.ONESPAN_ACCOUNT") }}
          </template>
          <div class="list-group-item onespan_api_key">{{ $t("COMMON.API_KEY") }} : {{ organization.onespan_api_key }}</div>
          <div class="list-group-item onespan_api_key">{{ $t("ESIGNS.CALL_BACK_URL") }} : {{ organization.esign_callback_url }}</div>
        </el-collapse-item>

        <el-collapse-item v-if="organization.dpaAccount" name="dpaAccount" class="list-group">
          <template slot="title">
            <span class="dpa-ppa-account-icon">
              <i class="fas fa-dollar-sign" aria-hidden="true"></i>
            </span>
            {{ $t("DPA_PPA.VIEW_ACCOUNT") }}
          </template>
          <div class="list-group-item">
            <dpa-account-view-global :dpa-account="organization.dpaAccount"/>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { Collapse, CollapseItem} from "element-ui";
import EsignIcon from '@/components/Icons/EsignIcon.vue';
import DpaAccountViewGlobal from "@/views/Pages/DpaModule/DpaAccountManagement/partials/DpaAccountViewGlobal.vue";
export default {
  components: {
    EsignIcon,
    DpaAccountViewGlobal,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  name: "organization-view-global",
  props: ["organization"],
  watch: {
    organization(organization) {},
  },
};
</script>
