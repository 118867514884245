<template>
  <div class="wallet mb-3">
    <el-card>
      <div class="col-12">
        <div class="row">
          <div class="col-md-6">
            <span class="balance-text">{{ $t("WALLET.TOTAL_BALANCE") }} :</span>
          </div>
          <div class="col-md-6">
            <div class="balance-badge d-flex align-items-center">
              <balance-badge
                type="withdraw"
                :amount="wallet.total_withdrawals"
                :symbol="wallet.meta.currency_symbol"
                class="mr-1"
              />
              <balance-badge
                type="deposit"
                :amount="wallet.total_deposits"
                :symbol="wallet.meta.currency_symbol"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <div class="balance mt-3 mb-3">
          <h1>{{ wallet.meta.currency_symbol }} {{ wallet.balance_float }}</h1>
        </div>
        <div class="align-items-center">
          <el-button
            type="success"
            class="align-items-center"
            round
            @click="handleOpenDepositForm('deposit')"
          >
            {{ $t("WALLET.DEPOSIT") }}
          </el-button>
          <el-button
            type="danger"
            @click="handleOpenDepositForm('withdraw')"
            round
            :disabled="!canWithdraw"
          >
            {{ $t("WALLET.WITHDRAW") }}
          </el-button>
        </div>
      </div>
    </el-card>
    <el-drawer
      :title="
        transactionType == 'deposit'
          ? $t('WALLET.DEPOSIT')
          : $t('WALLET.WITHDRAW')
      "
      :visible.sync="openDepositForm"
      direction="rtl"
      size="50%"
      append-to-body
    >
      <div class="transaction-form px-5">
        <transaction-form
          :wallet="wallet"
          :transaction-type="transactionType"
          @transactionCreated="handleTransactionCreated"
          @refreshPaymentMethodsList="refreshPaymentMethodsList"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import BalanceBadge from "./atoms/BalanceBadge.vue";
import TransactionForm from "./forms/TransactionForm.vue";
import { MIN_AMOUNT } from "../constants/common";

export default {
  name: "Wallet",
  components: {
    BalanceBadge,
    TransactionForm,
  },

  props: {
    wallet: {
      type: Object,
      default: () => {
        return {
          holder_type: "",
          holder_id: 1,
          slug: "",
          meta: {
            currency: "CA",
            currency_symbol: "$",
          },
          balance: 0,
          decimal_places: 2,
          total_deposits: 0,
          total_withdrawals: -0,
        };
      },
      required: false,
    },
    symbol: {
      type: String,
      default: "$",
    },
    forceWithdraw: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openDepositForm: false,
      transactionType: "deposit",
      minAmount: MIN_AMOUNT,
    };
  },
  computed: {
    canWithdraw() {
      return this.forceWithdraw || parseInt(this.wallet.balance_float) > this.minAmount;
    },
  },
  methods: {
    handleTransactionCreated() {
      this.openDepositForm = false;
      this.$emit("needRefresh", true);
    },
    refreshPaymentMethodsList() {
      this.$emit("refreshPaymentMethodsList", true);
    },
    handleOpenDepositForm(type) {
      this.transactionType = type;
      this.openDepositForm = true;
    },
  },
};
</script>
